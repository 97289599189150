const batchNumber = function(name){
    return [
        {
          name: name + 'List',
          title: '列表'
        },
        {
          name: name + 'fastPage',
          title: '生成批次'
        },
        {
          name: name + 'historyPage',
          title: '历史批次'
        },
        {
          name: name + 'Del',
          title: '删除'
        }
    ]
}

const packageCode = function(name){
    return [
        {
            name: name + 'List',
            title: '列表'
          },
          {
            name: name + 'fastPage',
            title: '生成批次'
          },
          {
            name: name + 'historyPage',
            title: '历史批次'
        }
    ]
}

const comingSequenceNumber = function(name){
  return [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Print',
      title: '打印'
    },
    {
      name: name + 'Cancel',
      title: '作废'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]
}

const comingBatchNumber = function(name){
  return [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Print',
      title: '打印'
    },
    {
      name: name + 'Cancel',
      title: '作废'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]
}

const finishedSequenceNumber = function(name){
  return [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Print',
      title: '打印'
    },
    {
      name: name + 'Cancel',
      title: '作废'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]
}
const packingBatchNumber = function(name){
  return [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Print',
      title: '打印'
    },
    {
      name: name + 'Cancel',
      title: '作废'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]
}


export default {
  path: 'barcode',
  name: 'Barcode',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '条码管理', icon: require('@/assets/l_c_S_images/模具管理.svg'), activeMenu: 'barcode' },
  redirect: '/barcode/barcode-management',
  children: [{
    path: 'barcode-management',
    name: 'BarcodeManagement',
    component: () => import('@/layout/blank.vue'),
    meta: { title: '条码管理', activeMenu: 'barcode-management' },
    redirect: '/barcode/barcode-management/batch-number',
    children: [
        {
            path: 'batch-number',
            name: 'batchNumber',
            component: () => import('@/views/information/print-label/batch-number/batch-number.vue'),
            meta: { title: '打印批次码', activeMenu: 'batch-number', buttons: batchNumber('batchNumber') }
        },
        {
            path: 'package-code',
            name: 'packageCode',
            component: () => import('@/views/information/print-label/package-code/package-code.vue'),
            meta: { title: '打印包装码', activeMenu: 'package-code', buttons: packageCode('packageCode') }
        },
        {
            path: 'coming-sequence-number',
            name: 'comingSequenceNumber',
            component: () => import('@/views/information/print-label/coming-sequence-number/index.vue'),
            meta: { title: '来料序列号', activeMenu: 'coming-sequence-number', buttons: comingSequenceNumber('comingSequenceNumber') }
        },
        {
          path: 'coming-batch-number',
          name: 'comingBatchNumber',
          component: () => import('@/views/information/print-label/coming-batch-number/index.vue'),
          meta: { title: '来料批次码', activeMenu: 'coming-batch-number', buttons: comingBatchNumber('comingBatchNumber') }
        },
        {
          path: 'finished-sequence-number',
          name: 'finishedSequenceNumber',
          component: () => import('@/views/information/print-label/finished-sequence-number/index.vue'),
          meta: { title: '成品序列号', activeMenu: 'finished-sequence-number', buttons: finishedSequenceNumber('finishedSequenceNumber') }
        },
        {
          path: 'packing-batch-number',
          name: 'packingBatchNumber',
          component: () => import('@/views/information/print-label/packing-batch-number/index.vue'),   
          meta: { title: '装箱批次码', activeMenu: 'packing-batch-number', buttons: packingBatchNumber('packingBatchNumber') }
        }
    ]
  }]
}
