import planButtons from './page-buttons/plan.buttons'
import informationButtons from './page-buttons/information.buttons'

export default {
  path: 'plans',
  name: 'plans',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '生产管理', icon: require('@/assets/l_c_S_images/生产管理.svg'), activeMenu: 'plans' },
  redirect: '/plans/plans-info/orders',
  children: [
    {
      path: 'plans-info',
      name: 'plansInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '生产管理', activeMenu: 'plans-info' },
      redirect: '/plans/plans-info/orders',
      children: [
        {
          path: 'orders',
          name: 'orders',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '生产订单', activeMenu: 'orders', canLink: true },
          redirect: '/plans/plans-info/orders/order-list',
          children: [
            {
              path: 'order-list',
              name: 'orderList',
              component: () => import('@/views/plans/orders/orders.vue'),
              meta: { noCache: false, roleTitle: '订单列表', title: '', activeMenu: 'orders', buttons: planButtons.orderList('orderList') },
              hidden: true
            },
            {
              path: 'order-add',
              name: 'orderAdd',
              component: () => import('@/views/plans/orders/order-add.vue'),
              meta: { noCache: false, title: '新增订单', activeMenu: 'orders', nb: true, buttons: planButtons.orderAdd('orderAdd') },
              hidden: true
            },
            {
              path: 'plan-add',
              name: 'planAdd',
              component: () => import('@/views/plans/lists/plan-add.vue'),
              meta: { noCache: true, title: '创建生产计划', activeMenu: 'orders', nb: true, buttons: planButtons.planAdd('planAdd') },
              hidden: true
            },
            {
              path: 'order-detail',
              name: 'orderDetail',
              component: () => import('@/views/plans/orders/order-detail.vue'),
              meta: { noCache: false, title: '订单详情', activeMenu: 'orders', nb: true, buttons: planButtons.orderDetail('orderDetail') },
              hidden: true
            }
          ]
        },

        {
          path: 'plan-list',
          name: 'planList',
          component: () => import('@/views/plans/lists/lists.vue'),
          meta: { noCache: true, title: '生产计划', activeMenu: 'plan-list', buttons: planButtons.planList('planList') }
        },
        {
          path: 'plan-orders-schedules',
          name: 'planOrderSchedules',
          component: () => import('@/views/plans/plan-schedules/plan-schedules.vue'),
          meta: { noCache: false, title: '排程甘特图', activeMenu: 'plan-orders-schedules' }
        },
        {
          path: 'pre-order',
          name: 'PreOrder',
          component: () => import('@/views/plans/prod-plan-lists/preOrderList.vue'),
          meta: { noCache: false, title: 'Pre-工单', activeMenu: 'pre-order', buttons: planButtons.PreOrder('PreOrder') }
        },
        {
          path: 'prod-plan',
          name: 'prodPlan',
          component: () => import('@/layout/blank.vue'),
          redirect: '/plans/plans-info/prod-plan/prod-plan-list',
          meta: { noCache: true, title: '生产工单', activeMenu: 'prod-plan', canLink: true },
          children: [
            {
              path: 'prod-plan-list',
              name: 'prodPlanList',
              component: () => import('@/views/plans/prod-plan-lists/lists.vue'),
              meta: {
                noCache: false,
                title: '工单列表',
                activeMenu: 'prod-plan',
                buttons: planButtons.prodPlanList('prodPlanList')
              },
              hidden: true
            },
            {
              path: 'prod-plan-create',
              name: 'prodPlanCreate',
              component: () => import('@/views/plans/prod-plan-lists/prod-plan-create.vue'),
              meta: {
                noCache: true,
                title: '工单详情',
                activeMenu: 'prod-plan',
                buttons: planButtons.prodPlanCreate('prodPlanCreate')
              },
              hidden: true
            }
          ]
        },
        {
          path: 'plan-bind',
          name: 'planBind',
          component: () => import('@/views/bind.vue'),
          meta: { noCache: true, title: '场地占用', activeMenu: 'plan-bind'},
        },
        {
          path: 'works',
          name: 'works',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '生产工单', activeMenu: 'works', canLink: true },
          redirect: '/plans/plans-info/works/work-list',
          children: [
            {
              path: 'work-list',
              name: 'workList',
              component: () => import('@/views/plans/works/work-list.vue'),
              meta: { noCache: false, roleTitle: '工单列表', title: '', activeMenu: 'works', buttons: planButtons.workList },
              hidden: true
            },
            {
              path: 'work-detail',
              name: 'workDetail',
              component: () => import('@/views/plans/works/work-detail.vue'),
              meta: { noCache: true, title: '工单详情', activeMenu: 'works', nb: true, buttons: planButtons.workDetail },
              hidden: true
            },
            {
              path: 'repair-detail',
              name: 'repairDetail',
              component: () => import('@/views/plans/works/repair-detail.vue'),
              meta: { noCache: false, title: '返修详情', activeMenu: 'works', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'production-task',
          name: 'ProductionTask',
          component: () => import('@/layout/blank.vue'),
          redirect: '/plans/plans-info/production-task/production-task-list',
          meta: {title: '生产任务', activeMenu: 'production-task', canLink: true},
          children: [
            {
              path: 'production-task-list',
              name: 'ProductionTaskList',
              component: () => import('@/views/project/MRP/materialsProduction/productionTask.vue'),
              meta: { noCache: false, roleTitle: '任务列表', title: '', activeMenu: 'production-task', buttons: planButtons.productionTask('ProductionTaskList') },
              hidden: true
            },
            {
              path: 'production-task-detail',
              name: 'ProductionTaskDetail',
              component: () => import('@/views/project/MRP/materialsProduction/detail.vue'),
              meta: { noCache: true, title: '任务详情', activeMenu: 'production-task', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'selfmade-task',
          name: 'SelfmadeTask',
          component: () => import('@/views/plans/works/work-list.vue'),
          meta: { noCache: true, title: '自制任务', activeMenu: 'selfmade-task',
            buttons: planButtons.SelfmadeTask('SelfmadeTask'), nameFlage:"OperatorTask" },
        },
        {
          path: 'materials-require',
          name: 'materialsRequire',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '用料清单', activeMenu: 'materials-require', canLink: true },
          redirect: '/plans/plans-info/materials-require/list',
          children: [
            {
              path: 'list',
              name: 'materialsRequireList',
              component: () => import('@/views/information/materials-require/materials-require.vue'),
              meta: { title: '用料清单列表', activeMenu: 'materials-require', buttons: informationButtons.materialsRequireList('materialsRequireList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'materialsRequireDetail',
              component: () => import('@/views/information/materials-require/detail/detail.vue'),
              meta: { title: '用料清单详情', noCache: true, activeMenu: 'materials-require', nb: true,buttons: informationButtons.materialsRequireDetail('materialsRequireDetail') },
              hidden: true
            }
          ]
        },
        {
          path: 'materials-apply',
          name: 'materialsApply',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '生产用料单', activeMenu: 'materials-apply', canLink: true },
          redirect: '/plans/plans-info/materials-apply/list',
          children: [
            {
              path: 'list',
              name: 'materialsApplyList',
              component: () => import('@/views/information/materials-apply/materials-apply.vue'),
              meta: {
                title: '用料单列表',
                activeMenu: 'materials-apply',
                buttons: informationButtons.materialsApplyList('materialsApplyList')
              },
              hidden: true
            },
            {
              path: 'detail',
              name: 'materialsApplyDetail',
              component: () => import('@/views/information/materials-apply/detail/detail.vue'),
              meta: {
                title: '领料单详情',
                noCache: true,
                activeMenu: 'materials-apply',
                nb: true,
                buttons: informationButtons.materialsApplyDetail('materialsApplyDetail')
              },
              hidden: true
            },
            {
              path: 'return-detail',
              name: 'materialsApplyReturnDetail',
              component: () => import('@/views/information/materials-apply/detail/return-detail.vue'),
              meta: {
                title: '退料单详情',
                noCache: true,
                activeMenu: 'materials-apply',
                nb: true,
                buttons: informationButtons.materialsApplyReturnDetail('materialsApplyReturnDetail')
              },
              hidden: true
            },
            {
              path: 'batch-apply',
              name: 'materialsBatchApply',
              component: () => import('@/views/information/materials-apply/detail/batch-apply.vue'),
              meta: { title: '批量领料', noCache: true, activeMenu: 'materials-apply', nb: true },
              hidden: true
            }
          ]
        },
        // {
        //   path: 'plan-schedules',
        //   name: 'planSchedules',
        //   component: () => import('@/views/plans/plan-schedules/plan-schedules.vue'),
        //   meta: { noCache: false, title: '排程甘特图', activeMenu: 'plan-schedules' }
        // },
        // {
        //   path: 'complete-set',
        //   name: 'completeSet',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '齐套计算', activeMenu: 'complete-set', canLink: true },
        //   redirect: '/plans/plans-info/complete-set/complete-set-list',
        //   children: [
        //     {
        //       path: 'complete-set-list',
        //       name: 'completeSetList',
        //       component: () => import('@/views/plans/completeSet/index.vue'),
        //       meta: { noCache: true, roleTitle: '齐套列表', title: '齐套计算', activeMenu: 'complete-set', buttons: planButtons.completeSet('completeSetList') },
        //       hidden: true
        //     },
        //     {
        //       path: 'add',
        //       name: 'completeSetAdd',
        //       component: () => import('@/views/plans/completeSet/add.vue'),
        //       meta: { noCache: false, roleTitle: '新建齐套计算', title: '新建齐套计算', activeMenu: 'complete-set' },
        //       hidden: true
        //     },
        //   ]
        // },
        {
          path: 'station-scheduling',
          name: 'stationScheduling',
          component: () => import('@/views/plans/stationScheduling/stationScheduling.vue'),
          meta: { noCache: false, title: '工位排程', activeMenu: 'station-scheduling' }
        },
        {
          path: 'out-source-lists',
          name: 'outSourceLists',
          component: () => import('@/views/plans/outSource/outSource.vue'),
          meta: { noCache: false, title: '外协申请', activeMenu: 'out-source-lists', buttons: planButtons.outSourceLists('outSourceLists') }
        },
        {
          path: 'outsourcing-task',
          name: 'OutsourcingTask',
          component: () => import('@/views/plans/works/work-list.vue'),
          meta: { noCache: true, title: '外协任务', activeMenu: 'outsourcing-task',
            buttons: planButtons.OutsourcingTask('OutsourcingTask'), nameFlage:"OutSourceTask" },
        },
        {
          path: 'report-records',
          name: 'reportRecords',
          component: () => import('@/views/plans/reportRecords/reportRecords.vue'),
          meta: { noCache: false, title: '报工记录', activeMenu: 'report-records', buttons: planButtons.reportRecords('reportRecords') }
        },
        {
          path: 'production-records',
          name: 'ProductionRecords',
          component: () => import('@/views/plans/productionRecords/reportRecords.vue'),
          meta: { noCache: false, title: '投产记录', activeMenu: 'production-records', buttons: planButtons.reportRecords('ProductionRecords') }
        },
        {
          path: 'working-hours',
          name: 'WorkingHours',
          component: () => import('@/views/plans/prod-plan-lists/workingHours.vue'),
          meta: {
            noCache: true,
            title: '工时统计',
            activeMenu: 'working-hours',
            buttons: planButtons.WorkingHours('WorkingHours')
          }
        },
        {
          path: 'up_down_work_record',
          name: 'upDownWorkRecord',
          component: () => import('@/views/plans/upDownWork/index.vue'),
          meta: {
            noCache: true,
            title: '上下工记录',
            activeMenu: 'up_down_work_record',
            buttons: planButtons.UpDownWorkRecord('UpDownWorkRecord')
          }
        },
        {
          path: 'break_point',
          name: 'BreakPoint',
          component: () => import('@/views/plans/breakPoint/index.vue'),
          meta: {
            noCache: true,
            title: '设变断点',
            activeMenu: 'break_point',
            buttons: planButtons.BreakPoint('BreakPoint')
          }
        },
        {
          path: 'performance-salary',
          name: 'PerformanceSalary',
          component: () => import('@/views/plans/statistic/index.vue'),
          meta: {
            noCache: true,
            title: '工资绩效',
            activeMenu: 'performance-salary',
            buttons: planButtons.performanceSalary('PerformanceSalary')
          }
        },
        {
          path: 'split-order',
          name: 'SplitOrder',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '拆分清单', activeMenu: 'split-order', canLink: true },
          redirect: '/plans/plans-info/split-order/list',
          children: [
            {
              path: 'list',
              name: 'SplitOrderList',
              component: () => import('@/views/plans/split-list/list.vue'),
              meta: { title: '拆分清单列表', activeMenu: 'split-order', buttons: informationButtons.splitList('SplitOrderList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'SplitOrderDetail',
              component: () => import('@/views/plans/split-list/detail/detail.vue'),
              meta: { title: '拆分清单详情', noCache: true, activeMenu: 'split-order', nb: true,buttons: informationButtons.splitListDetail('SplitOrderDetail') },
              hidden: true
            }
          ]
        },
        {
          path: 'packing-order',
          name: 'PackingOrder',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '装箱清单', activeMenu: 'packing-order', canLink: true },
          redirect: '/plans/plans-info/packing-order/list',
          children: [
            {
              path: 'list',
              name: 'PackingOrderList',
              component: () => import('@/views/plans/packing-list/list.vue'),
              meta: { title: '装箱清单列表', activeMenu: 'packing-order', buttons: informationButtons.packingList('PackingOrderList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'PackingOrderDetail',
              component: () => import('@/views/plans/packing-list/detail/detail.vue'),
              meta: { title: '装箱清单详情', noCache: true, activeMenu: 'packing-order', nb: true,buttons: informationButtons.packingListDetail('PackingOrderDetail') },
              hidden: true
            }
          ]
        }
      ]
    }
  ]
}

